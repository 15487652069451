




import Vue from 'vue';

export default Vue.extend({
  name: 'DeleteTestAccount',
  created() {
    this.deleteAccount();
  },
  methods: {
    async deleteAccount(): Promise<void> {
      try {
        await this.$store.dispatch('user/deleteAccount');
        this.$router.push('/signin');
        this.$store.dispatch('application/globalStateReset');
      } catch (error) {
        this.$notify.error(error.message);
      }
    },
  },
});
